.customer {
    display: grid;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 0.02fr 0.2fr 1.5fr 0.6fr;
    grid-template-areas:
        "title"
        "dlbutton"
        "tableArea"
        "navbutton";
    z-index: 100;
    height: 100vh;
}

.title {
    grid-area: title;
}

.dlbutton{
    grid-area: dlbutton;
    display: flex;
}

.navbutton {
    grid-area: navbutton;
    display: flex;
}

.table{
    grid-area: tableArea;
    max-height: 36rem;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
}

.infoCard{
    grid-area: infoCard;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "lastMonth accType freeSpace";
}

.pricing {
    grid-area: priceCard;
}
.daterangeSelectionContainer{
    position: relative;
    background: white;
}
.daterangeSelection{
    position: absolute;
    z-index: 1;
    background: white;
    padding: 5px;
}
