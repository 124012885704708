.CookieConsent{
    z-index: 9999999999 !important;
}

.CopyButton{
    background: #edecec;
    padding: 5px;
    font-weight: bold;
    color: #8d8b8b;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 5px;
    top: 14px;
}