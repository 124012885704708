.manageSub {
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    align-items: center;
}

.title {
    grid-area: title;
}

.infoCard{
    grid-area: infoCard;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "lastMonth accType freeSpace";
}

.pricing {
    grid-area: priceCard;
}