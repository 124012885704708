.flexContainer {
  display: "flex";
  flex-direction: "row";
  flex-wrap: "wrap";
  justify-content: "center";
  align-items: "center";
}

@media (max-width: 1297px) {
    .flexContainer {
        display: "flex";
    flex-direction: "column";
    flex-wrap: "wrap";
    justify-content: "center";
    align-items: "center";
    }
}
