.manageSub {
    display: grid;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 0.2fr 0.6fr 1.5fr 0.4fr;
    grid-template-areas: 
        "title"
        "infoCard"
        "priceCard"
        "cancelButton";
    align-items: center;
    z-index: 100;
    overflow: hidden;
    overflow-y: scroll;
}

.title {
    grid-area: title;
}

.infoCard{
    grid-area: infoCard;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "lastMonth accType freeSpace";
}

.pricing {
    grid-area: priceCard;
}