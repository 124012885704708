.exampleAPI {
    display: grid;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 0.2fr 900px 1200px 2fr;
    grid-template-areas: 
        "title"
        "shapeDoc"
        "chartDoc"
        "furtherExamples";
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100;
}

.title {
    grid-area: title;
}