// Your variable overrides
$sidebar-bg-color: #ffffff;
$sidebar-color: #000000;
$highlight-color: #4b4b4b !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';
.signout-link{
    position: absolute;
    bottom: 0;
    border-top: 1px solid #d7d7d7;
    width: 100%;
    font-weight: 500;
    padding: 10px;
    .signout-up-icon{
        display: inline;
        position: relative;
        top: 3px;
        left: 5px;
    }
    .signout-down-icon{
        display: inline;
        position: relative;
        top: 3px;
        left: 5px;
    }
    .signout-container{
        position: absolute;
        bottom: 45px;
        right: 5px;
        width: 90%;
        background: #fff;
        padding: 10px;
        border: 1px solid transparent;
        box-shadow: 0px 0px 16px #ccc;
        font-weight: normal;
        .link-list{
            padding: 5px;
        }
        .link-list:hover{
            background-color: #eee;
        }
    }
}
