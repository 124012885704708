.button {
    background-color: rgba(51, 65, 85, var(--tw-text-opacity));;
    color: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    justify-content: center;
}

.button:hover{
    background-color: rgba(51, 65, 85, var(--tw-text-opacity));;
}

@media screen and (max-width: 1024px) {
    .button {
        background-color: black;
        color: white;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 5px;
        justify-content: center;
    }
    
    .button:hover{
        background-color: darkgray;
    }
}

.dropdownUl{
    background: white;
    padding-bottom: 10px;
}