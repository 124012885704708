.gettingStarted {
    display: grid;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    grid-template-columns: 1.5fr 0.5fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 0.2fr 600px;
    grid-template-areas: 
        "title title"
        "startDoc example";
    z-index: 100;
}

.title {
    grid-area: title;
}