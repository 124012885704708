.email {
    text-decoration: none;
    color: white;
}

.email:hover {
    text-decoration: underline;
    color: white;
}


.customerTableThead{
    position:sticky;
    top:0
  }
  