.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popUpContent {
    width: 75%;
}

.buttonContainer {
    height: 150px;
    position: relative;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }


.text {
    line-height: 2
}

.text2 {
  line-height: 5
}

.divider{
  width:50px;
  height:auto;
  display:inline-block;
}


.icon-arrow-right {
  float: right;
  margin-top: 2px;
  margin-right: -6px;
  }