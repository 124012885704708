.grid-profession {
    display: grid;
    grid-template-rows: 50px 50px;
    grid-template-columns: 260px 260px;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-areas:
        "button1 button2"
        "button3 button3";
    grid-gap: 5px !important;
    z-index: 100;
}

.grid-role {
    display: grid;
    grid-template-rows: 50px 50px;
    grid-template-columns: 173px 174px 173px;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-areas:
        "button1 button2 button3"
        "button4 button5 button6";
    grid-gap: 5px !important;
    z-index: 100;
}

.grid-goal {
    display: grid;
    grid-template-rows: 50px 50px 50px;
    grid-template-columns: 260px 260px;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-areas:
        "button1 button2"
        "button3 button4"
        "button5 button5";
    grid-gap: 5px !important;
    z-index: 100;
}

.grid-item:nth-child(1) {
    grid-area: button1;
}

.grid-item:nth-child(2) {
    grid-area: button2;
}

.grid-item:nth-child(3) {
    grid-area: button3;
}

.grid-item:nth-child(4) {
    grid-area: button4;
}

.grid-item:nth-child(5) {
    grid-area: button5;
}

.grid-item:nth-child(6) {
    grid-area: button6;
}

.grid-item:nth-child(7) {
    grid-area: button7;
}

.grid-item:nth-child(8) {
    grid-area: button8;
}

.span-goal {
    grid-column: 1 / 3;
}

.button {
    background-color: lightgrey;
    color: black;
    border-radius: 5px;
    text-align: center;
    text-justify: auto;
}

    .button:hover {
        background-color: grey;
        color: black;
        border-radius: 5px;
        text-align: center;
        text-justify: auto;
    }


.button-submit {
    background-color: lightgrey;
    color: black;
    border-radius: 5px;
    text-align: center;
    text-justify: auto;
    width: 100%;
    height: 50px;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.enabled {
    opacity: 1.0;
    background-color: green;
    color:white
}

 