.adminArea {
    display: grid;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 0.25fr 300px 600px 600px;
    grid-template-areas: 
        "title title"
        "bearerForm bearerToken"
        "genDoc genDoc"
        "authDoc authDoc";
    z-index: 100;
    overflow: hidden;
    overflow-y: scroll;
}

@media (max-width: 900px) {
    .adminArea {
        grid-template-rows: 0.25fr 380px 600px 600px;
    }
  }
  

.title {
    grid-area: title;
}

